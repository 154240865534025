import React from 'react';
import { Typography, Box } from '@mui/material';
import Loading from '../../common/Loading';
import { Item } from './Item';
import { SvgIconComponent } from '@mui/icons-material';

interface KeyStatsCardProps {
    title: string;
    value: number | null;
    icon: SvgIconComponent;
}

export const KeyStatsCard: React.FC<KeyStatsCardProps> = ({
    title,
    value,
    icon: Icon
}) => {
    return (
        <Item sx={{ flexDirection: 'row', borderRadius: '25px', justifyContent: 'flex-start', alignItems: 'center', padding: '10px 20px' }}>
            <Icon color='primary' sx={{fontSize: '45px'}} />
            <Box sx={{ marginLeft: '25px' }}>
                <Typography variant='h5' fontWeight='bold'>
                    {value??<Loading />}
                </Typography>
                <Typography>
                    {title}
                </Typography>
            </Box>
        </Item>
    );
};
