import { FC, useEffect, useState } from 'react';
import { Grid, Cell } from 'styled-css-grid';
import { Chart } from 'react-google-charts';
import styled from 'styled-components';
import { useQuery } from 'urql';
import { Box, Typography } from '@mui/material';
import useNumberParams from '../hooks/useNumberParams';
import { aiInsightsByProjectQuery } from '../api/query/aiInsights.queries';
import Loading from '../components/common/Loading';
import WordCloudComponent from '../components/common/WordCloud';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const Container = styled.div`
  margin: 20px;
`;

const CommentInsightsLayout = styled(Grid)`
  grid-template-areas:
    'summary summary wordcloud'
    'themes themes themes'
    'subthemes subthemes subthemes';
  grid-gap: 20px;
  margin-top: 20px;
`;

const CommentSummary = styled(Cell)`
  grid-area: summary;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
`;

const WordCloudArea = styled(Cell)`
  grid-area: wordcloud;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ThemesBreakdown = styled(Cell)`
  grid-area: themes;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
`;

const SubthemesBreakdown = styled(Cell)`
  grid-area: subthemes;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
`;
const colors = ['#00A396', '#00B3A3', '#68D2C9', '#0076A0', '#0081AE', '#68B4CF'];
const getRandomColor = (num: number): string[] => {
  // Shuffle the colors array and take the first `num` elements
  const shuffledColors = [...colors].sort(() => 0.5 - Math.random());
  return shuffledColors.slice(0, num);
};

const Insights: FC = () => {
  const { projectId } = useNumberParams();
  const options = {
    chartArea: { top: 50, width: '50%' },
    hAxis: {
      format: '#\'%\'', // Custom format to add a percentage sign
      title: 'Percent of Comments',
      minValue: 0,
    },
    vAxis: {
      title: 'Theme',
    },
    annotations: {
      alwaysOutside: true, // Ensure annotations are outside the bars
      textStyle: {
        fontSize: 12,
        bold: true,
        color: '#000' // Annotation text color
      }
    },
    legend: { position: 'none' },
  };
  const [{ data }] = useQuery({
    query: aiInsightsByProjectQuery,
    variables: { projectId },
  });

  const [themeColors, setThemeColors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (data && data.aiInsightsByProject && data.aiInsightsByProject.themes) {
      const themes = data.aiInsightsByProject.themes;
      // Assign random colors to each theme
      const uniqueColors = getRandomColor(themes.length);

      const colorsMap: Record<string, string> = themes.reduce((acc: Record<string, string>, theme: Record<string, string>, index: number) => {
        acc[theme.name] = uniqueColors[index];
        return acc;
      }, {});

      setThemeColors(colorsMap);
    }
  }, [data]);

  const insights = data?.aiInsightsByProject;
  const wordCounts = insights?.commonCommentWordCounts.map((w: { word: string, count: number }) => { return [w.word, w.count]; });
  return (
    <Container>
      <CommentInsightsLayout columns="1fr 1fr">
        <CommentSummary>
          <h2>Comment Summary</h2>
          {insights && insights.commentSummaries && insights.commentSummaries.length > 0 ? (
            insights.commentSummaries.map((s: { title: string; summary: string }, index: number) => (
              <Box
                key={index}
                sx={{
                  marginBottom: '25px',
                  padding: '16px',
                  borderRadius: '8px',
                  backgroundColor: '#f0fdf4', // Light green background color similar to the image
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)', // Adds a subtle shadow to the box
                }}
              >
                <Typography sx={{ marginBottom: '30px' }} variant="h5" fontWeight="bold" gutterBottom>
                  {s.title}
                </Typography>
                <Typography variant="h6">
                  {s.summary}
                </Typography>
              </Box>
            ))
          ) : (
            <Box sx={{ minHeight: '48px', my: '24px' }}>
              <Loading />
            </Box>
          )}
        </CommentSummary>
        <WordCloudArea>
          {/* Your Word Cloud Content */}
          <h2>Word Cloud</h2>
          {insights && insights.commonCommentWordCounts && insights.commonCommentWordCounts.length > 0 ?
            (
              <>
                <WordCloudComponent words={wordCounts} />
                <h4>Top 10</h4>
                {insights.commonCommentWordCounts.slice(0, 10).map((w: { word: string, count: number }, i: number) => (
                  <Link to={`/projects/${projectId}/comments?term=${w.word}`}><h4>{i + 1}. {w.word} ({w.count})</h4></Link>
                ))}
              </>
            )
            :
            (<Box sx={{ minHeight: '48px', my: '24px' }}>
              <Loading />
            </Box>)
          }
        </WordCloudArea>
        <ThemesBreakdown>
          {/* Your Themes Breakdown Content */}
          <h2>Comment Report Themes Breakdown</h2>
          {insights && insights.themes && insights.themes.length > 0 ?
            (<Chart
              chartType='BarChart'
              width='100%'
              height='600px'
              options={options}
              data={[
                ['Name', 'Percent', { role: 'style' }, { role: 'annotation' }, { role: 'tooltip' }],  // Header row for the chart
                ...insights.themes.sort((a: { name: string, percent: number },b: { name: string, percent: number }) => b.percent - a.percent).map((item: { name: string, percent: number }) => [item.name, item.percent, themeColors[item.name], `%${item.percent}`, `%${item.percent}`]),  // Transform each data item
              ]}
            />)
            :
            (<Box sx={{ minHeight: '48px', my: '24px' }}>
              <Loading />
            </Box>)
          }

        </ThemesBreakdown>
        <SubthemesBreakdown>
          {/* Your Subthemes Breakdown Content */}
          <h2>Comment Report Subthemes Breakdown</h2>
          {insights && insights.subThemes && insights.subThemes.length > 0 ?
            (<>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '15px', backgroundColor: 'white' }}>
                {Object.entries(themeColors).map(([theme, color]) => (
                  <div key={theme} style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                    <div
                      style={{
                        backgroundColor: color,
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        marginRight: '5px',
                      }}
                    ></div>
                    <span style={{ fontSize: '14px' }}>{theme}</span>
                  </div>
                ))}
              </div>
              <Chart
                chartType='BarChart'
                options={options}
                height='900px'
                data={[
                  ['Name', 'Percent', { role: 'style' }, { role: 'annotation' }, { role: 'tooltip' }],  // Header row for the chart
                  ...insights.subThemes.sort((a: { name: string, percent: number },b: { name: string, percent: number }) => b.percent - a.percent).map((item: { name: string, percent: number, themeName: string }) => [item.name, item.percent, themeColors[item.themeName], `%${item.percent}`, `%${item.percent}`]),  // Transform each data item
                ]}
              /></>)
            :
            (<Box sx={{ minHeight: '48px', my: '24px' }}>
              <Loading />
            </Box>)
          }
        </SubthemesBreakdown>
        {insights && insights.aiInsightsLastExecutionDate &&
          (
            <Typography sx={{ fontSize: '16px', marginTop: '10px' }}>
              Updated: {dayjs(insights.aiInsightsLastExecutionDate).format('MM/DD/YYYY')}
            </Typography>
          )
        }
      </CommentInsightsLayout>
    </Container >
  );
};

export default Insights;