import { gql } from 'urql';

export const visitorAnalyticsByProjectQuery = gql`
	query ($projectId: Int!) {
		visitorAnalyticsByProject(projectId: $projectId) {
			totalVisitors
			monthlyIncreaseInVisitors
		}
	}
`;

export const feedbackAnalyticsByProjectQuery = gql`
	query ($projectId: Int!) {
		feedbackAnalyticsByProject(projectId: $projectId) {
			adminCommentReplyRate
			adminCommentReplyTotal
			commentsAddedToPlan
			commentMetrics {
				total
				totalsForMonth
			}
			pollMetrics {
				total
				totalsForMonth
			}
			commentsOverTimePeriod {
				createdDate
				count
			}
			questionAnswersOverTimePeriod {
				createdDate
				count
			}
			adminCommentsOverTimePeriod {
				createdDate
				count
			}
			sentiments {
				positiveSentiments
				neutralSentiments
				negativeSentiments
				positiveAndNeutralTotal
			}
			aiInsightsAvailable
			lettersOfSupport
		}
	}
`;

export const followAnalyticsByProjectQuery = gql`
	query ($projectId: Int!) {
		followersAnalyticsByProject(projectId: $projectId) {
			followers {
				fullName
				created
				avatarUrl
			}
			followersMetric {
				total
				totalsForMonth
			}
			followersOverTimePeriod {
				createdDate
				count
			}
		}
	}
`;
