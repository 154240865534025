import { EventType } from '../hooks/useProjectSettings';

const determineModal = (type: EventType, isDisabled: boolean) => {
	switch (type) {
		case 'launchedDate':
			return {
				title: 'Launch Site',
				message:
					'Are you sure you want to launch the project? Once launched, the site is live!',
				confirmLabel: 'Launch',
			};
		case 'pausedDate':
			return {
				title: `${isDisabled ? 'Pause' : 'Resume'} Site`,
				message: `Are you sure you want to ${
					isDisabled ? 'pause' : 'resume'
				} the project?`,
				confirmLabel: `${isDisabled ? 'Pause' : 'Resume'}`,
			};
		case 'disabledDate':
			return {
				title: `${isDisabled ? 'Disable' : 'Enable'} Site`,
				message: `Are you sure you want to ${
					isDisabled ? 'disable' : 'enable'
				} the project?`,
				confirmLabel: `${isDisabled ? 'Disable' : 'Enable'}`,
			};
		case 'archivedDate':
			return {
				title: `${isDisabled ? 'Archive' : 'Unarchive'} Site`,
				message: `Are you sure you want to ${
					isDisabled ? 'archive' : 'unarchive'
				} the project?`,
				confirmLabel: `${isDisabled ? 'Archive' : 'Unarchive'}`,
			};
		case 'emailEnabledDate':
			return {
				title: `${isDisabled ? 'Enable' : 'Disable'} Scheduled Emails`,
				message: `Are you sure you want to ${
					isDisabled ? 'enable' : 'disable'
				} scheduled emails?`,
				confirmLabel: `${isDisabled ? 'Enable' : 'Disable'}`,
			};
		case 'localizeProjectKey':
			return {
				title: 'Save Localize Key',
				message: 'Are you sure you want to save localize key?',
				confirmLabel: '',
			};
		case 'translationEnabledDate':
			return {
				title: `${isDisabled ? 'Enable' : 'Disable'} Translation`,
				message: `Are you sure you want to ${
					isDisabled ? 'enable' : 'disable'
				} translation?`,
				confirmLabel: `${isDisabled ? 'Enable' : 'Disable'}`,
			};
		case 'customUrlEnabledDate':
			return {
				title: `${isDisabled ? 'Enable' : 'Disable'} Custom Domain`,
				message: `Are you sure you want to ${
					isDisabled ? 'enable' : 'disable'
				} custom domain?`,
				confirmLabel: `${isDisabled ? 'Enable' : 'Disable'}`,
			};
		case 'customUrl':
			return {
				title: 'Save Custom Domain',
				message: 'Are you sure you want to save custom domain?',
				confirmLabel: '',
			};
		case 'emailSandbox':
			return {
				title: 'Save Email Sandbox',
				message: 'Are you sure you want to save email sandbox?',
				confirmLabel: '',
			};
		case 'emailSandboxEnabledDate':
			return {
				title: `${isDisabled ? 'Enable' : 'Disable'} Email Sandbox`,
				message: `Are you sure you want to ${
					isDisabled ? 'enable' : 'disable'
				} email sandbox?`,
				confirmLabel: `${isDisabled ? 'Enable' : 'Disable'}`,
			};
		case 'smsSubscribeEnableDate':
			return {
				title: `${isDisabled ? 'Enable' : 'Disable'} SMS Subscribe`,
				message: `Are you sure you want to ${
					isDisabled ? 'enable' : 'disable'
				} SMS Subscribe?`,
				confirmLabel: `${isDisabled ? 'Enable' : 'Disable'}`,
			};
		case 'smsSubscribeMobileNumber':
			return {
				title: 'Save SMS Subscribe Number?',
				message: 'Are you sure you want to save this SMS subscribe number?',
				confirmLabel: '',
			};
		case 'metaDescription':
			return {
				title: 'Save this SEO meta description?',
				message: 'Are you sure you want to save this SEO meta description?',
				confirmLabel: '',
			};
		case 'showFeedbackCount':
			return {
				title: 'Save Show Feedback Count',
				message: 'Are you sure you want to save show feedback count?',
				confirmLabel: '',
			};
		case 'aiInsightsEnabledDate':
			return {
				title: `${isDisabled ? 'Enable' : 'Disable'} AI Insights?`,
				message: `Are you sure you want to ${
					isDisabled ? 'enable' : 'disable'
				} AI Insights?`,
				confirmLabel: `${isDisabled ? 'Enable' : 'Disable'}`,
			};
		default:
			return {
				title:
					'<Developer>Set a title for this modal in determineModal.ts</Developer>',
				message:
					'<Developer>Create a new case in determineModal.ts with the correct title and message<Developer>',
				confirmLabel: '',
			};
	}
};

export default determineModal;
